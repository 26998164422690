exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-central-js": () => import("./../../../src/pages/central.js" /* webpackChunkName: "component---src-pages-central-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-socially-responsible-js": () => import("./../../../src/pages/socially-responsible.js" /* webpackChunkName: "component---src-pages-socially-responsible-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-why-cooperatives-js": () => import("./../../../src/pages/why-cooperatives.js" /* webpackChunkName: "component---src-pages-why-cooperatives-js" */),
  "component---src-pages-why-invest-js": () => import("./../../../src/pages/why-invest.js" /* webpackChunkName: "component---src-pages-why-invest-js" */)
}

